import {Container} from '~/modules/Layout/components/Container';
import {FC} from 'react';
import {Text} from './Text';
import {useExpert} from '../hooks/useExpert';

interface MilkDrunkCalloutProps {}

export const MilkDrunkCallout: FC<MilkDrunkCalloutProps> = () => {
  const {expertSlug} = useExpert();
  return expertSlug.toLowerCase() === 'milkdrunk' ? (
    <Container className="p-2">
      <Text
        size="copy"
        className="text-codGray500 text-center"
        as="p"
        width="full"
      >
        Our editorial team personally selects each featured product. If you buy
        something through our links, we may earn an affiliate commission, at no
        cost to you.
      </Text>
    </Container>
  ) : null;
};
